<template>
  <div>
    <div :class="containerClass" @click="onWrapperClick">
      <div class="layout-sidebar" @click="onSidebarClick">
        <div class="layout-logo-wrapper flex items-center relative">
          <router-link
            to="/"
            class="layout-logo-text absolute left-1/2 transform -translate-x-1/2">
            <span>SERVIDOR</span>
          </router-link>
          <div class="notification-btn-wrapper absolute right-0">
            <Button
              icon="pi pi-bell"
              class="notification-btn"
              @click="toggleOverlay" />
            <span v-show="ultimaMensagem" class="badge">1</span>
          </div>
        </div>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>

      <AppTopBar :class="topBarClass" @menu-toggle="onMenuToggle" />
      <div>
        <Dialog
          :visible="visible"
          modal
          header="Consentimento LGPD"
          :style="{ width: '80%', padding: '10px' }"
          :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
          <ConsentimentoLgpd></ConsentimentoLgpd>
          <template #footer>
            <Button
              label="Recusar"
              icon="pi pi-times"
              class="p-button-danger md:col-12"
              text
              @click="logout()" />
            <Button
              label="Aceitar"
              icon="pi pi-check"
              class="p-button-success md:col-12"
              autofocus
              @click="consentirLgpd()" />
          </template>
        </Dialog>
      </div>
      <div class="layout-main-container">
        <div class="layout-main">
          <router-view />
          <Dialog
            :visible.sync="exibirFormEmail"
            header="Atualizar Dados"
            :modal="true"
            class="p-fluid"
            :closable="false">
            <fieldset :disabled="saving" class="formgrid grid">
              <div class="field col-12 md:col-12">
                <h6>
                  Por favor, atualize seu e-mail/telefone para poder liberar
                  acesso ao sistema.
                </h6>
              </div>
              <div class="field col-12 md:col-12">
                <label for="telefone">Telefone</label>
                <InputMask
                  id="telefone"
                  v-model.trim="servidor.celular"
                  mask="(99)99999-9999" />
                <div
                  v-if="submitted && v$.servidor.celular.required.$invalid"
                  class="p-error">
                  O campo Telefone é obrigatório.
                </div>
              </div>
              <div class="field col-12 md:col-12">
                <label for="mail">E-mail</label>
                <InputText v-model="servidor.email" />
                <div
                  v-if="submitted && v$.servidor.email.required.$invalid"
                  class="p-error">
                  O campo E-mail é obrigatório.
                </div>
                <div
                  v-if="submitted && v$.servidor.email.email.$invalid"
                  class="p-error">
                  E-mail é inválido.
                </div>
              </div>
            </fieldset>
            <template #footer>
              <Button
                :disabled="saving"
                label="Cancelar"
                icon="pi pi-times"
                class="p-button-text"
                @click="fecharFormEmail" />
              <Button :disabled="saving" autofocus @click="salvarServidor">
                <span v-if="!saving" class="pi pi-check"></span>
                <span v-if="saving" class="pi pi-spin pi-spinner"></span>
                <span v-if="!saving" class="ml-2">Salvar</span>
                <span v-if="saving" class="ml-2">Aguarde</span>
              </Button>
            </template>
          </Dialog>
        </div>
        <AppFooter />
      </div>

      <transition name="layout-mask">
        <div
          v-if="mobileMenuActive"
          class="layout-mask p-component-overlay"></div>
      </transition>
    </div>
    <div>
      <OverlayPanel v-if="ultimaMensagem" ref="op">
        <OverlayPanel v-if="ultimaMensagem" ref="op">
          <div
            v-for="(mensagem, index) in ultimaMensagem"
            :key="index"
            class="flex flex-column gap-3 w-25rem">
            <p><strong>Aviso Global:</strong> {{ mensagem.mensagem }}</p>
          </div>
        </OverlayPanel>
      </OverlayPanel>
    </div>
    <div>
      <Dialog
        v-if="ultimaMensagem"
        :visible.sync="dialogVisible"
        :modal="true"
        :closable="true"
        :style="{ width: '45rem' }">
        <div v-for="(mensagem, index) in ultimaMensagem" :key="index">
          <p><strong>Aviso Global:</strong> {{ mensagem.mensagem }}</p>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import AppTopbar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppFooter from './AppFooter.vue'
import MensagemService from '@/service/MensagemService.js'
import ServidorService from '@/service/ServidorService'
import ConsentimentoLgpdService from '@/service/ConsentimentoLgpdService'
import consentimentoLgpd from '@/components/shared/consentimentoLgpd/ConsentimentoLgpd'

export default {
  components: {
    AppTopBar: AppTopbar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
    ConsentimentoLgpd: consentimentoLgpd,
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      dialogVisible: false,
      ultimaMensagem: null,
      layoutMode: 'static',
      staticMenuInactive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home',
          items: [
            {
              label: 'Início',
              icon: 'pi pi-fw pi-home',
              to: { name: 'matriculas' },
            },
            {
              label: 'Consentimento',
              icon: 'pi pi-fw pi-shield',
              to: { name: 'consentimento' },
            },
            {
              label: 'Relatórios',
              icon: 'pi pi-fw pi-print',
              to: { name: 'relatorio' },
            },
            {
              label: 'Simulador de Consignado',
              icon: 'pi pi-fw pi-chart-bar',
              to: { name: 'simulador' },
            },
            {
              label: 'Autorizações',
              icon: 'pi pi-fw pi-check-circle',
              to: { name: 'autorizacaoPortabilidade' },
            },
          ],
        },
      ],
      servidor: {},
      exibirFormEmail: false,
      saving: false,
      submitted: false,
      qtdConsentidoLgpd: 0,
      visible: false,
      textoLGPD: `
          TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS DOS USUÁRIOS DA PLATAFORMA PBCONSIG DA CODATA – COMPANHIA DE PROCESSAMENTO DE DADOS DA PARAÍBA

          1. O que é a LGPD?

          A Lei Geral de Proteção de Dados (13.709/2018) tem como principal objetivo proteger os direitos fundamentais de liberdade, de privacidade e livre desenvolvimento da personalidade da pessoa natural.A LGPD estabelece normas para a coleta e o tratamento de dados pessoais, a fim de impedir vazamento de dados e assegurar a privacidade e a proteção deles.

          2. Base Legal e Finalidade do tratamento de dados pelo PBconsig:

          O Artigo 7º da Lei Geral de Proteção de Dados (LGPD) estabelece as hipóteses de bases legais para o tratamento de dados pessoais por uma empresa ou instituição, com o objetivo de proteger os direitos fundamentais de liberdade e privacidade do seu titular. 
          O tratamento de dados pessoais somente poderá ser realizado nas seguintes hipóteses:
          I - Mediante o fornecimento de consentimento pelo titular;
          II - Para o cumprimento de obrigação legal ou regulatória pelo controlador;
          III - Pela administração pública, para o tratamento e uso compartilhado de dados necessários à execução de políticas públicas previstas em leis e regulamentos ou respaldadas em contratos, convênios ou instrumentos congêneres, observadas as disposições do Capítulo IV desta Lei;

          Com base nessa hipótese de Execução de políticas públicas. (Art. 7º, III, LGPD ou Art. 11, II, "b", LGPD), a CODATA pode tratar os dados dos titulares para atender rotinas de tratamento de dados com a finalidade de promover análise de crédito e gestão dos consignados contratados pelos servidores públicos junto às consignatárias devidamente credenciadas e autorizadas pela Secretaria de Estado da Administração (SEAD) e CODATA conforme decreto estadual de n° 41.993/2021.

          3. Consentimento

          Para que o titular dos dados possa dar o seu consentimento para o tratamento de suas informações pessoais, ele deve ser informado de maneira clara, objetiva e transparente sobre a finalidade da coleta das informações, por meio de um termo de consentimento. 

          Este documento visa registrar de acordo com o Art. 5º, Inciso XII, da LGPD, que o consentimento é a “manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada”.

          Ao manifestar sua aceitação para com o presente termo, o Titular consente e concorda que a CODATA, CNPJ nº 09.189.499/0001-00, com sede no Centro Administrativo Estadual, Rua João da Mata, S/N, Jaguaribe - CEP: 58.015-020 - Fone: (83) 3208-4490 / (83) 98658-8328 – João Pessoa – Paraíba, e-mail do setor jurídico pbconsig@codata.pb.gov.br, doravante denominada Controladora, realize o tratamento dos dados pessoais especificados na tabela 1 - Tratamento de Dados Pessoais - com a finalidade de cadastrar o titular como usuário externo em sistema informatizado da CODATA para acesso e assinatura eletrônica em autorização de acesso aos dados e margens para empréstimos, novas averbações, reserva de cartão de crédito e adiantamento,  renegociação e compra de dividas (portabilidade) pelos consignatários indicados, pelo período de vigência da temporalidade do processo administrativo. 

          4. Dados coletados

          Nome completo do titular, Documento de Identidade RG, CPF, Matrícula, Vínculo, Órgão, Data de admissão, Data de afastamento, Munícpio lotação, Salário, Situação (exonerado, afastado, ativo, falecido), E-mail, Celular, Data de nascimento, Sexo, Estado Civil, N° do contrato, Valor Prestação.

          5. Direitos dos Titulares

          O titular terá direito de informação, acesso, correção, anonimização, bloqueio ou eliminação, portabilidade e revogação do seu consentimento. O titular poderá exercer seus direitos enviando um pedido por e-mail para: gpd@codata365.pb.gov.br

          6. Compartilhamento de Dados

          Os dados serão compartilhados com as Consignatárias devidamente credenciadas e autorizadas pela Secretaria de Estado da Administração (SEAD) e CODATA conforme decreto estadual de n° 41.993/2021.

          7. Revogação

          Estou ciente de que, a qualquer momento, posso retirar o consentimento ora fornecido, hipótese em que as atividades desenvolvidas pela CODATA, no âmbito da nossa relação. Declaro e concordo que os meus Dados Pessoais poderão ser armazenados, mesmo após o término do tratamento – inclusive após a revogação do consentimento para cumprimento de obrigação legal ou regulatória pela CODATA. 

          Links Importantes

          Link para a LGPD – Lei Geral de Proteção de Dados
          http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709compilado.htm

          Link Tratamento de Dados Pessoais Pelo Poder Público
          https://www.gov.br/anpd/pt-br/documentos-e-publicacoes/guia-poder-publico-anpd-versao-final.pdf

          • Considerando que fui informado sobre como será realizada a coleta e utilização dos Dados, bem como será o tratamento dos Dados pelo PBconsig pela CODATA/PB, declaro o meu consentimento em relação ao tratamento dos Dados para as finalidades aqui propostas.


          CENTRO ADMINISTRATIVO ESTADUAL
          Rua João da Mata, S/N, Jaguaribe - CEP: 58.015-020 - Fone: (83) 3208-4490 / (83) 98658-8328 - JOÃO PESSOA - PARAÍBA

      `,
      consentimentoLgpd: {},
    }
  },

  validations() {
    return {
      servidor: {
        email: { required, email },
        celular: { required },
      },
    }
  },

  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },

    topBarClass() {
      return [
        {
          'layout-topbar-responsive':
            this.staticMenuInactive && this.layoutMode === 'static',
        },
      ]
    },
  },

  async created() {
    this.setaPerfil()
    const service = new MensagemService(this.$http)
    try {
      this.ultimaMensagem = await service.buscarUltimaMensagem(this.perfil)
      this.dialogVisible = true
    } catch (error) {
      console.error('Erro ao buscar última mensagem:', error)
      // Tratar erro conforme necessário
    }
    this.ativarTimerParaAtualizarToken()
    if (this.tokenExpirado()) {
      this.logout()
    }
    this.consentimentoLgpdService = new ConsentimentoLgpdService(this.$http)
  },

  mounted() {
    this.servidorService = new ServidorService(this.$http)
    this.carregarServidor()
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    toggleDialogVisibility() {
      this.dialogVisible = !this.dialogVisible
    },
    setaPerfil() {
      const hostname = window.location.hostname
      const parts = hostname.split('.')
      let subdomain
      if (parts[0] === 'dev') {
        subdomain = parts[1]
      } else {
        subdomain = parts[0]
      }
      switch (subdomain) {
        case 'gestao':
          this.perfil = 'gestao'
          this.perfilLabel = 'Gestão'
          break
        case 'consignataria':
          this.perfil = 'consignataria'
          this.perfilLabel = 'Consignatária'
          break
        case 'servidor':
          this.perfil = 'servidor'
          this.perfilLabel = 'Servidor'
          break
        default:
          this.perfil = 'servidor'
          this.perfilLabel = 'Servidor'
          break
      }
    },
    toggleOverlay(event) {
      this.$refs.op.toggle(event)
    },
    carregarServidor() {
      this.servidorService
        .getDadosServidor()
        .then((res) => {
          this.servidor = res
          // this.verificarEmail()
          this.consentimentoLgpd.servidorId = res.id
          this.consentimentoLgpd.textoLgpd = this.textoLGPD
          this.consentimentoLgpdService
            .quantidadeConsentimentoEmUso(this.servidor.id)
            .then((resp) => {
              this.visible = resp.data === 0 ? true : false
            })
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
          this.$router.push('/logout')
        })
    },

    verificarEmail() {
      if (
        this.servidor.email == null ||
        this.servidor.email === '' ||
        this.$auth.user.email == null ||
        this.$auth.user.email === '' ||
        this.servidor.celular == null ||
        this.servidor.celular === ''
      ) {
        this.exibirFormEmail = true
      } else {
        this.exibirFormEmail = false
      }
    },

    fecharFormEmail() {
      this.saving = false
      this.v$.$reset()
      this.exibirFormEmail = false
      this.exibeToast('email-form-error')
      this.$router.push('/logout')
    },

    salvarServidor() {
      if (this.validate()) {
        this.saving = true
        this.servidorService
          .saveServidor(this.servidor)
          .then((res) => {
            this.servidor = res
            this.exibirFormEmail = false
            this.saving = false
            this.exibeToast('servidor-success')
          })
          .catch((err) => {
            this.exibeToast('error', err.response.data.message)
            this.saving = false
          })
      }
    },

    validate() {
      this.submitted = true
      this.v$.servidor.$touch()
      if (this.v$.servidor.$invalid) {
        return false
      }
      return true
    },

    exibeToast(tipo, msg) {
      if (tipo === 'servidor-success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Servidor atualizado com sucesso.',
          life: 10000,
        })
      } else if (tipo === 'servidor-error') {
        this.$toast.add({
          severity: msg,
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'email-form-error') {
        this.$toast.add({
          severity: 'error',
          summary:
            'É necessário atualizar o e-mail/telefone para ter acesso ao sistema.',
          life: 10000,
        })
      }
    },

    tempoRestante() {
      return this.$auth.getParsedToken().exp - Date.now() / 1000
    },

    tokenExpirado() {
      return this.tempoRestante() < 0
    },

    logout() {
      clearInterval(this.timer)
      this.$auth.logout()
      window.location.reload()
    },

    ativarTimerParaAtualizarToken() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.tokenExpirado()) {
            this.logout()
          } else if (this.tempoRestante() < 140) {
            this.$auth.refreshToken()
          }
        }, 70000)
      }
    },

    isDesktop() {
      return window.innerWidth >= 992
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },

    onSidebarClick() {
      this.menuClick = true
      this.mobileMenuActive = false
    },

    onMenuToggle() {
      this.menuClick = true
      if (this.isDesktop()) {
        this.staticMenuInactive = !this.staticMenuInactive
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }
      event.preventDefault()
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },

    consentirLgpd() {
      this.consentimentoLgpdService
        .create(this.consentimentoLgpd)
        .then((res) => {
          console.log(res)
          this.visible = false
        })
    },
  },
}
</script>

<style>
.notification-btn-wrapper {
  margin-top: 1.5rem;
  margin-right: -0.5rem;
}
.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
}
.notification-btn {
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
